import React, { useState, useEffect } from "react";
import { Document, Page, Text, View, Image, StyleSheet, PDFDownloadLink } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    color: "#333333",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: "#F8F8F8",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    color: "#FFFFFF",
    backgroundColor: "#6495ED",
    padding: 10,
    margin: 10,
    borderRadius: 5,
  },
  subtitle: {
    fontSize: 18,
    margin: 10,
    textAlign: "center",
    color: "#6495ED",
  },
  text: {
    margin: 5,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    color: "#666666",
  },
  textbold: {
    margin: 5,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    color: "#333333",
    fontWeight: "bold",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "#A0A0A0",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#A0A0A0",
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#D3D3D3",
  },
  column: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
  },
  image: {
    marginVertical: 1,
    width: 150, 
    height: 150, 
    borderRadius: 60, 
    objectFit: "cover", 
    alignSelf: "center", 
  },
 
});

const DocuPDF = ({ jsonDatos }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(jsonDatos);
  }, [jsonDatos]);

  const imageUrl = `data:image/jpeg;base64,${jsonDatos.toChild?.data?.userData?.foto ?? ""}`;

  if (!jsonDatos || !jsonDatos.toChild || !jsonDatos.toChild.data || !jsonDatos.toChild.data.userData) {
    return <div>No hay datos disponibles para generar el PDF.</div>;
  }

  const pdfDoc = (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>Datos Personas Poder Judicial</Text>
        <Text style={styles.subtitle}>RENAPER</Text>

        <View style={styles.container}>
          <View style={styles.column}>
            {imageUrl ? (
              <Image style={styles.image} src={imageUrl} />
            ) : (
              <Text style={styles.text}>No hay imagen disponible</Text>
            )}
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Nombres:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.nombres ?? "sin datos"}</Text>
            <Text style={styles.text}>Documento:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.documento ?? "sin datos"}</Text>
            <Text style={styles.text}>Ejemplar Documento:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.ejemplar ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Apellido:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.apellido ?? "sin datos"}</Text>
            <Text style={styles.text}>Fecha de Nacimiento:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.fecha_nacimiento ?? "sin datos"}</Text>
          </View>
        </View>

        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.text}>Edad:</Text>
            <Text style={styles.textbold}>
              {jsonDatos.toChild?.data?.userData?.mensaje_fallecido === "FALLECIDO" ? "Persona Fallecida" : jsonDatos.toChild?.data?.userData?.edad ?? "sin datos"}
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Sexo:</Text>
            <Text style={styles.textbold}>{(jsonDatos.toChild?.sexo ?? "sin datos").toUpperCase()}</Text>
          </View>

          {jsonDatos.toChild?.data?.userData?.edad === "--" && (
            <View style={styles.column}>
              <Text style={styles.text}>Fecha de Fallecimiento :</Text>
              <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.fecha_FallecimientoFull ?? "sin datos"}</Text>
            </View>
          )}


        </View>
        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.text}>País:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.pais ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Provincia:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.provincia ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Municipio:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.municipio ?? "sin datos"}</Text>
          </View>
        </View>

        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.text}>Domicilio:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.domicilioFull ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Ciudad:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.ciudad ?? "sin datos"}</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.text}>Código Postal:</Text>
            <Text style={styles.textbold}>{jsonDatos.toChild?.data?.userData?.codigo_postal ?? "sin datos"}</Text>
          </View>
        </View>

        <Text style={styles.header} fixed>
          Constancia Emitida: {new Date().toLocaleString()}
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFDownloadLink 
        document={pdfDoc}   
        fileName={`Constancia ${jsonDatos?.toChild?.data?.userData?.apellido ?? "sin datos"}.pdf`}
      >
        {({ loading, error }) => (
          <div>
            {loading && <span>Generando documento...</span>}
            {error && <span>Error: {error.message}</span>}
            <button disabled={loading} style={{ backgroundColor: "#1976d2", color: "white", padding: 10, borderRadius: 5, border: "none", cursor: "pointer" }}>
              Descargar Constancia
            </button>
          </div>
        )}
      </PDFDownloadLink>
    </div>
  );
};

export default DocuPDF;
