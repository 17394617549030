import * as React from 'react';
import {useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Consult from './Consult';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import {getUserQuota} from '../services/persona';
import Chip from '@mui/material/Chip';
import Terms from "./terms";


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Dirección de Informática -'}
      <Link color="inherit" href="https://www.jus.mendoza.gov.ar/">
        Poder Judicial de Mendoza
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Result() {



  const rQuota={total:"0",quota_current_day:"0",month:"0",year:"0"};

  const [userFullName,setUserFullName] = React.useState('');
  const [restingQuota,setRestingQuota] = React.useState({});


// function getUserQ()
// {
//   let userName='cristiandiaz'
 
//   getUserQuota(userName).then(r=>{
//     setRestingQuota(r.data);
//     console.log("setting data quota")
//     console.log(r.data)
//   });
// }

function logout()
{
  let initOptions = {
    url: "https://dev-auth.pjm.gob.ar/auth",
    realm: "devops",
    clientId: "renaper",
    onLoad: "login-required",
  }

  let  encoded_url=process.env.REACT_APP_URL_LOGOUT_REDIRECT_ENCODED

  let logout_url=initOptions.url + '/realms/' + initOptions.realm + '/protocol/openid-connect/logout?redirect_uri=' + encoded_url
  // console.log(logout_url);
   localStorage.clear();
   
  // alert()
   window.location.replace(logout_url);
 
}


  useEffect(() => {
setUserFullName("Hola, "+localStorage.getItem('HumanName')??'');


let userName=localStorage.getItem('userName')
 
  getUserQuota(userName).then(r=>{
    setRestingQuota(r.data.data);
  
  });


  },[]);


  useEffect(() => {
   
    // console.log("updated data quota");
    
      },[restingQuota]);


  // console.log("from result",restingQuota);



  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar sx={{ display: 'flex' }}>

          <Typography variant="h6" color="inherit" noWrap >
            {userFullName}
          </Typography>

          <Box sx={{ flexGrow: 1  }}  />
          <Badge sx={{ m :1 }} color="primary" badgeContent={restingQuota.quota_current_day || "0"}>
           <Chip icon={<IconButton />} label="Día" variant="outlined" />
          </Badge>
          <Badge sx={{ m :1 }} color="primary" badgeContent={restingQuota.quota_current_month  || "0" }>
           <Chip icon={<IconButton />} label="Mes" variant="outlined" />
          </Badge>
          <Badge sx={{ m :1 }} color="primary" badgeContent={restingQuota.quota_current_year || "0" }>
           <Chip icon={<IconButton />} label="Año" variant="outlined" />
          </Badge>
          <Badge sx={{ m :1 , mr: 4 }} color="primary" badgeContent={restingQuota.quota_current_total || "0" }>
           <Chip icon={<IconButton />} label="Total" variant="outlined" />
          </Badge>
         
          {/* <Box sx={{ ml: 'auto' }}> */}
  
            {/* <IconButton
                size="large"s
                edge="end"
                aria-label="Cerrar Sesión"
                aria-haspopup="true"
                color="inherit"
                onClick={() => { logout() }}
              >
                <LogoutIcon />
            </IconButton>
           
          </Box> */}
         

         
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth= "lg" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center" marginBottom="1em">
            Personas
          </Typography>
        
          <React.Fragment>
     
              <React.Fragment>
              <Consult setRestingQuota={setRestingQuota} />
              <Terms/>
               
              </React.Fragment>
          
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}

